<template>
  <v-img
    :aspect-ratio="img.w / img.h"
    :lazy-src="img.b64"
    :src="imgix.src"
    :srcset="w ? null : imgix.srcset"
    sizes="100vw"
    :eager="eager"
    :style="w ? ('max-width: ' + w + 'px') : null"
  >
    <template v-slot:placeholder>
      <v-skeleton-loader
        class="mx-auto"
        type="card"
      />
    </template>
  </v-img>
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'
import { buildUrlObject } from 'vue-imgix'

export default {
  name: 'ImgCmp',
  mixins: [FileMixin],
  props: {
    img: Object,
    w: Number,
    q: Number,
    eager: Boolean
  },
  data () {
    return {
      previewSrc: null
    }
  },
  computed: {
    imgix () {
      if (this.$cfg.imgix?.domain) {
        const options = {}
        if (this.w) {
          options.w = this.w
        }
        if (this.q) {
          options.q = this.q
        }
        const src = 'contents/' + this.img.src
        return buildUrlObject(src, options)
      } else {
        return { src: this.previewSrc }
      }
    }
  },
  created () {
    if (!this.$cfg.imgix && this.img.src) {
      this.getFileURL(this.img.src).then(src => {
        this.previewSrc = src
      })
    }
  }
}
</script>
