<template>
  <div class="sign-up-in">
    <v-snackbar v-model="showErrMsg" top color="red">
      {{ errMsg }}
    </v-snackbar>

    <div class="text-h4 mb-10">Registrieren</div>

    <v-tabs-items v-model="step">
      <v-tab-item value="start">
        <v-sheet v-if="$cfg.signUp && $cfg.signUp.intro" color="grey lighten-3" class="pa-2 mb-5 text-body-2">{{ $cfg.signUp.intro }}</v-sheet>
        <div class="d-flex">
          <v-text-field v-model="forename" label="Vorname" autocomplete="given-name" class="mr-3" style="flex: 1"/>
          <v-text-field v-model="lastname" label="Nachname" autocomplete="family-name" clear-icon="ml-3" style="flex: 1"/>
        </div>
        <v-text-field v-model="email" type="email" label="E-Mail" hint="Die E-Mail Adresse wird verifiziert"/>
        <div class="d-flex">
          <v-text-field v-model="password" label="Passwort" type="password" hint="Mind. 6 Zeichen" class="mr-3" style="flex: 1"/>
          <v-text-field v-model="password_repeat" label="Passwort wiederholen" type="password" :rules="[v => !v || v === password || 'Passworte nicht identisch']" class="mr-3" style="flex: 1"/>
        </div>
        <v-checkbox v-if="$cfg.signUp && $cfg.signUp.terms" v-model="terms" class="terms-checkbox mx-2">
          <template slot="append">
            <div v-html="$cfg.signUp.terms" class="ml-3"/>
          </template>
        </v-checkbox>
        <div><v-btn outlined :disabled="!isStartValid" :loading="loading" class="mt-6" @click="signUp">Weiter</v-btn></div>
        <div><v-btn text small :disabled="loading" class="my-6" @click="$emit('cancel')">Zurück</v-btn></div>
      </v-tab-item>

      <v-tab-item value="email_confirm">
        <v-sheet color="grey lighten-3" class="pa-2 mb-6 text-body-2">Du erhälst in Kürze eine E-Mail mit einem Bestätigungscode, gib diesen hier ein:</v-sheet>
        <v-otp-input v-model="emailOtp" type="number" length="6" hide-spin-buttons @finish="signUp"/>
        <div><v-btn outlined :disabled="!isEmailOtpValid" :loading="loading" class="mt-6" @click="signUp">Weiter</v-btn></div>
        <div><v-btn text small :disabled="loading" class="my-6" @click="emailSent = false">Zurück</v-btn></div>
      </v-tab-item>

      <v-tab-item value="phone">
        <v-label>Mobile-Nr</v-label>
        <vue-tel-input v-model="phoneNumber" class="my-1" @validate="phoneObject = $event"/>
        <div v-if="$root.platform !== 'ios'" class="caption">Die Mobile-Nr wird per SMS verifiziert.</div>
        <div><v-btn outlined :disabled="!isPhoneNumberValid || !reCaptchaReady" :loading="loading" class="mt-6" @click="signUp">Weiter</v-btn></div>
        <div v-if="!$cfg.signUp || !$cfg.signUp.phoneRequired"><v-btn text small :disabled="loading" class="my-6" @click="skipPhone = true">Überspringen</v-btn></div>
      </v-tab-item>

      <v-tab-item value="phone_confirm">
        <v-sheet color="grey lighten-3" class="pa-2 mb-5 text-body-2">Du erhälst in Kürze eine SMS mit einem Bestätigungscode, gib diesen hier ein:</v-sheet>
        <v-otp-input v-model="smsCode" type="number" length="6" hide-spin-buttons autocomplete="one-time-code" @finish="signUp"/>
        <div><v-btn outlined :disabled="!isSmsCodeValid" :loading="loading" class="mt-6" @click="signUp">Weiter</v-btn></div>
        <div><v-btn text small :disabled="loading" class="my-6" @click="smsSent = false">Zurück</v-btn></div>
      </v-tab-item>

      <v-tab-item value="address">
        <address-fields v-model="address" @valid="isAddressValid = $event"/>
        <div><v-btn outlined :disabled="!isAddressValid" :loading="loading" class="mt-6" @click="signUp">Weiter</v-btn></div>
        <div v-if="!$cfg.signUp || !$cfg.signUp.addressRequired"><v-btn text small :disabled="loading" class="my-6" @click="skipAddress = true">Überspringen</v-btn></div>
      </v-tab-item>

      <v-tab-item value="profile">
        <user-profile-edit ref="userProfile" :user="$root.user" :name="name" @valid="isProfileValid = $event">
          <div><v-btn outlined :disabled="!isProfileValid" :loading="loading" class="mt-6" @click="signUp">Weiter</v-btn></div>
          <div v-if="!$cfg.signUp || !$cfg.signUp.profileRequired"><v-btn text small :disabled="loading" class="my-6" @click="doSkipProfile">Überspringen</v-btn></div>
        </user-profile-edit>
      </v-tab-item>

      <v-tab-item value="done">
        <div><v-icon color="primary" class="mb-6" style="font-size: 120px;">mdi-check-circle</v-icon></div>
        <v-alert v-if="$cfg.signUp && $cfg.signUp.moderated && !$root.user.reviewed" type="success" class="text-left">
          Vielen Dank für deine Anmeldung - sobald diese geprüft wurde, geht es hier weiter!
        </v-alert>
        <div v-else>
          <v-sheet v-if="$cfg.signUp && $cfg.signUp.outro" color="grey lighten-3" class="pa-2 mb-5 text-body-2">{{ $cfg.signUp.outro }}</v-sheet>
          <v-btn outlined :loading="loading" class="mt-6" @click="signUp">Los geht's</v-btn>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import AddressFields from '@/components/base/AddressFields'
import UserProfileEdit from '@/components/base/UserProfileEdit'

export default {
  name: 'SignUp',
  components: { AddressFields, UserProfileEdit },
  data () {
    return {
      name: null,
      forename: null,
      lastname: null,
      email: null,
      emailSent: false,
      emailOtp: null,
      address: {},
      isAddressValid: false,
      isProfileValid: false,
      skipAddress: false,
      phoneNumber: null,
      phoneObject: null,
      reCaptchaReady: true, // TODO: TEST REAL (set to false)
      smsSent: false,
      smsCode: null,
      skipPhone: false,
      isProfileSaved: false,
      skipProfile: false,
      password: null,
      password_repeat: null,
      passwordSet: false,
      terms: false,
      loading: false,
      errMsg: null,
      showErrMsg: false
    }
  },
  computed: {
    user () {
      return this.$root.user
    },
    isStartValid () {
      return this.forename && this.lastname && this.isEmailValid && this.isPasswordValid &&
        (!this.$cfg.signUp.terms || this.terms)
    },
    isEmailValid () {
      return /\S+@\S+\.\S+/.test(this.email)
    },
    isPasswordValid () {
      return this.password && this.password.length > 5 && this.password === this.password_repeat
    },
    isEmailOtpValid () {
      return this.emailOtp && this.emailOtp.length === 6 && !isNaN(this.emailOtp)
    },
    isPhoneNumberValid () {
      return this.phoneObject && this.phoneObject.valid
    },
    isSmsCodeValid () {
      return this.smsCode && this.smsCode.length === 6 && !isNaN(this.smsCode)
    },
    step () {
      if (!this.user) {
        return !this.emailSent ? 'start' : 'email_confirm'
      } else {
        if (this.user.reviewed === false) {
          return 'done' // wait for moderation
        }
        if (!this.user.phoneNumber && !this.user.telm && !this.skipPhone) { // phoneNumber will not trigger (auth obj) like telm (user obj - db listener)
          return !this.smsSent ? 'phone' : 'phone_confirm'
        } else if (!this.user.address?.city && !this.skipAddress) {
          return 'address'
        } else if (!this.isProfileSaved && !this.skipProfile) {
          return 'profile'
        } else {
          return 'done'
        }
      }
    }
  },
  methods: {
    async signUp () {
      this.loading = true
      if (this.step === 'start') {
        await this.sendEmailOtp()
      } else if (this.step === 'email_confirm') {
        await this.signUpWithEmailOtp()
      } else if (this.step === 'phone') {
        // phone auth currently not supported on iOS, save via func
        if (this.$root.platform !== 'ios') {
          await this.sendSmsCode()
        } else {
          await this.saveUserPhone()
        }
      } else if (this.step === 'phone_confirm') {
        await this.confirmSmsCode()
      } else if (this.step === 'address') {
        await this.saveAddress()
      } else if (this.step === 'profile') {
        await this.saveProfile()
      } else if (this.step === 'done') {
        await this.setOK()
      }
      this.loading = false
    },
    async sendEmailOtp () {
      const sendEmailOtpFunc = this.$fb.fn.httpsCallable('sendEmailOtp')
      const response = await sendEmailOtpFunc({
        email: this.email
      })
      if (response.data.ok) {
        this.emailSent = true
      } else {
        console.log('sendEmailOtp error', response.data.err)
        this.showErr(response.data.err)
      }
    },
    async signUpWithEmailOtp () {
      const signUpWithEmailOtpFunc = this.$fb.fn.httpsCallable('signUpWithEmailOtp')
      const response = await signUpWithEmailOtpFunc({
        forename: this.forename,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
        otp: this.emailOtp
      })
      if (response.data.ok) {
        await this.$fb.auth.signInWithEmailAndPassword(this.email, this.password)
      } else {
        console.log('signUpWithEmailOtp error', response.data.err)
        this.showErr(response.data.err)
      }
    },
    async sendSmsCode () {
      const phoneProvider = new this.$fb.fb.auth.PhoneAuthProvider()
      await phoneProvider.verifyPhoneNumber(this.phoneObject.number, this.recaptchaVerifier)
        .then(verificationId => { // sms sent
          this.verificationId = verificationId
          this.smsSent = true
          console.log('sendSmsCode OK')
        })
        .catch(err => {
          // TODO: https://firebase.google.com/docs/auth/web/phone-auth#manual-testing
          // reset recaptcha verifier, so that user can try again
          // grecaptcha.reset(window.recaptchaWidgetId)
          // or
          // this.recaptchaVerifier.render().then(widgetId => {
          //   grecaptcha.reset(widgetId)
          // })
          console.log('sendSmsCode error', err)
          this.showErr(err)
        })
    },
    async confirmSmsCode () {
      const credential = this.$fb.fb.auth.PhoneAuthProvider.credential(this.verificationId, this.smsCode)
      await this.$fb.auth.currentUser.linkWithCredential(credential)
        .then(async () => {
          console.log('confirmSmsCode OK')
          // not sure why, but captcha logo otherwise stays visible close to the bottom right corner
          document.getElementById('recaptcha-container').remove()
          // also set telm
          await this.$fb.db.doc('users/' + this.user.uid).update({
            telm: this.phoneObject.number
          })
          this.$root.phoneNumber = this.phoneObject.number // speed up things - should receive telm anyway (user listener)
        })
        .catch(err => {
          console.log('confirmSmsCode error', err)
          this.showErr(err)
        })
    },
    async saveUserPhone () {
      const saveUserPhoneFunc = this.$fb.fn.httpsCallable('saveUserPhone')
      const response = await saveUserPhoneFunc({
        phoneNumber: this.phoneObject.number
      })
      if (response.data.ok) {
        this.$root.phoneNumber = this.phoneObject.number // speed up things - should receive telm anyway (user listener)
      } else {
        console.log('saveUserPhone error', response.data.err)
        this.showErr(response.data.err)
      }
    },
    async saveAddress () {
      await this.$fb.db.doc('users/' + this.user.uid).update({
        address: this.address || {}
      })
    },
    async saveProfile () {
      await this.$refs.userProfile.save()
      if (this.$cfg.signUp?.moderated && !this.user.reviewed) { // if not pre-reviewed (import, admin add)
        await this.$fb.db.doc('users/' + this.user.uid).update({ reviewed: false }) // to stay on review screen after reloads
      }
      this.isProfileSaved = true
    },
    async doSkipProfile () {
      if (this.$cfg.signUp?.moderated && !this.user.reviewed) { // if not pre-reviewed (import, admin add)
        await this.$fb.db.doc('users/' + this.user.uid).update({ reviewed: false }) // to stay on review screen after reloads
      }
      this.skipProfile = true
    },
    async setOK () {
      await this.$fb.db.doc('users/' + this.user.uid).update({
        ok: true,
        tagIds: Object.keys(this.$root.tags || []) // on signUp / first signIn, subscribe to all tags currently available
      })
    },
    showErr (err) {
      const label = 'err_' + (err.code || err).replace(/\/|-/gi, '_')
      const msg = this.$te(label) ? this.$t(label) : (err.message || err)
      this.errMsg = msg
      this.showErrMsg = true
    }
  },
  watch: {
    step () {
      this.loading = false
    },
    showErrMsg (showErrMsg) {
      if (!showErrMsg) {
        this.errMsg = null
      }
    }
  },
  created () {
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new this.$fb.fb.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.reCaptchaReady = true
        }
      })
    }
  }
}
</script>

<style scoped>
.terms-checkbox >>> .v-input__control {
  width: unset;
}
.terms-checkbox >>> .v-input__append-outer {
  flex: 100;
  padding-top: 4px;
}
</style>