<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ $cfg.appTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <v-container class="d-flex justify-center text-center pt-10 pb-15">
        <div v-if="mode === 'welcome'">
          <img-cmp v-if="$cfg.logo" :img="$cfg.logo" class="mb-10" style="width: 80vw; max-width: 300px"/>
          <div v-if="$cfg.welcome" class="text-h4 mb-6">{{ $cfg.welcome }}</div>
          <div><v-btn outlined class="my-6" @click="mode = 'signIn'">Anmelden</v-btn></div>
          <div v-if="$cfg.signUp"><v-btn outlined small class="mb-6" @click="mode = 'signUp'">Registrieren</v-btn></div>
        </div>

        <sign-in v-else-if="mode === 'signIn'" @cancel="mode = 'welcome'"/>

        <sign-up v-else-if="mode === 'signUp'" @cancel="mode = 'welcome'"/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ImgCmp from '@/components/base/ImgCmp'
import SignIn from '@/components/auth/SignIn'
import SignUp from '@/components/auth/SignUp'

export default {
  name: 'SignUpIn',
  components: { ImgCmp, SignIn, SignUp },
  data () {
    return {
      mode: 'welcome'
    }
  },
  watch: {
    '$root.user': {
      handler (user) {
        if (user && user.uid && !user.ok) {
          this.mode = 'signUp'
        }
      },
      immediate: true
    }
  }
}
</script>
