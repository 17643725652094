<template>
  <div class="sign-up-in">
    <v-snackbar v-model="showErrMsg" top color="red">
      {{ errMsg }}
    </v-snackbar>

    <div class="text-h4 mb-10">Anmelden</div>

    <div v-if="step === 'start'" class="px-10">
      <div><v-btn outlined block class="mt-5" @click="step = 'email'">E-Mail &amp; Passwort</v-btn></div>
      <!-- Phone auth currently not supported on iOS -->
      <div v-if="$root.platform !== 'ios'"><v-btn outlined block class="mt-5" @click="step = 'phone'">SMS Code</v-btn></div>
      <div><v-btn text small class="my-6" @click="$emit('cancel')">Zurück</v-btn></div>
    </div>

    <div v-if="step === 'email'">
      <v-alert v-if="resetPwMailSent" type="success" class="text-left">
        Wir haben dir eine E-Mail zum setzen eines neuen Passworts gesendet. Nachdem du dort ein neues Passwort gewählt hast, kannst du dich hier damit anmelden.
      </v-alert>
      <v-text-field v-model="email" label="E-Mail" type="email"/>
      <v-text-field v-model="password" label="Passwort" type="password" @keyup.enter="isEmailValid ? signInWithEmailAndPassword() : null"/>
      <div><v-btn outlined :disabled="!isEmailValid || !password" :loading="loading" class="mt-6" @click="signInWithEmailAndPassword">Anmelden</v-btn></div>
      <div v-if="!resetPwMailSent"><v-btn text small :disabled="!isEmailValid" class="mt-3" @click="step = 'resetpw'">Passwort vergessen?</v-btn></div>
      <div><v-btn text small :disabled="loading" class="my-6" @click="step = 'start'">Zurück</v-btn></div>
    </div>

    <div v-if="step === 'resetpw'">
      <v-text-field v-model="email" label="E-Mail" type="email"/>
      <div><v-btn outlined :disabled="!isEmailValid" :loading="loading" class="mt-6" @click="sendPasswordResetEmail">Passwort zurücksetzen</v-btn></div>
      <div><v-btn text small :disabled="loading" class="my-6" @click="step = 'email'">Zurück</v-btn></div>
    </div>

    <div v-if="step === 'phone'">
      <div v-if="!smsSent">
        <v-label>Mobile-Nr</v-label>
        <vue-tel-input v-model="phoneNumber" @validate="phoneObject = $event" @keyup.enter="isPhoneNumberValid && reCaptchaReady ? signInWithPhone() : null"></vue-tel-input>
        <div><v-btn outlined :disabled="!isPhoneNumberValid || !reCaptchaReady" :loading="loading" class="mt-6" @click="signInWithPhone">Anmelden</v-btn></div>
        <div><v-btn text small :disabled="loading" class="my-6" @click="step = 'start'">Zurück</v-btn></div>
      </div>
      <div v-else>
        <v-sheet color="grey lighten-3" class="pa-2 mb-5 text-body-2">Du erhälst innert wenigen Sekunden eine SMS mit einem 6-stelligen Zahlencode.</v-sheet>
        <v-text-field v-model="smsCode" label="SMS Code" hint="6-stelliger Zahlencode" type="number" autocomplete="one-time-code" @keyup.enter="isSmsCodeValid ? confirmSms() : null"/>
        <div><v-btn outlined :disabled="!isSmsCodeValid" :loading="loading" class="mt-6" @click="confirmSms">Weiter</v-btn></div>
        <div><v-btn text small :disabled="loading" class="my-6" @click="smsSent = false">Zurück</v-btn></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignIn',
  data () {
    return {
      step: 'start',
      email: '',
      password: '',
      resetPwMailSent: false,
      phoneNumber: null,
      phoneObject: null,
      reCaptchaReady: true, // TODO: TEST REAL (set to false)
      smsSent: false,
      smsCode: '',
      loading: false,
      errMsg: null,
      showErrMsg: false
    }
  },
  computed: {
    isEmailValid () {
      return /\S+@\S+\.\S+/.test(this.email)
    },
    isPhoneNumberValid () {
      return this.phoneObject && this.phoneObject.valid
    },
    isSmsCodeValid () {
      return this.smsCode && this.smsCode.length === 6 && !isNaN(this.smsCode)
    }
  },
  methods: {
    async signInWithEmailAndPassword () {
      this.loading = true
      await this.$fb.auth.signInWithEmailAndPassword(this.email, this.password)
        .then(userCredential => {
          // noop
        })
        .catch(err => {
          console.log('signInWithEmailAndPassword error', err)
          this.showErr(err)
          this.loading = false
        })
    },
    async sendPasswordResetEmail () {
      this.loading = true
      await this.$fb.auth.sendPasswordResetEmail(this.email)
        .then(userCredential => {
          this.resetPwMailSent = true
          this.step = 'email'
          this.loading = false
        })
        .catch(err => {
          console.log('sendPasswordResetEmail error', err)
          this.showErr(err)
          this.loading = false
        })
    },
    async signInWithPhone () { // verify if this account exists! sign-in only, no sign-up here!
      this.loading = true
      const userExistsFunc = this.$fb.fn.httpsCallable('userExists')
      const response = await userExistsFunc({
        phoneNumber: this.phoneObject.number
      })
      if (response.data.ok) {
        await this.sendSmsCode()
      } else {
        console.log('signInWithPhone error', response.data.err)
        this.showErr(response.data.err)
        this.loading = false
      }
    },
    async sendSmsCode () {
      this.loading = true
      await this.$fb.auth.signInWithPhoneNumber(this.phoneObject.number, this.recaptchaVerifier)
        .then(confirmationResult => { // sms sent
          this.confirmationResult = confirmationResult
          this.smsSent = true
          this.loading = false
        })
        .catch(err => {
          // TODO: https://firebase.google.com/docs/auth/web/phone-auth#manual-testing
          // reset recaptcha verifier, so that user can try again
          // grecaptcha.reset(window.recaptchaWidgetId)
          // or
          // this.recaptchaVerifier.render().then(widgetId => {
          //   grecaptcha.reset(widgetId)
          // })
          console.log('sendSmsCode error', err)
          this.showErr(err)
          this.loading = false
        })
    },
    async confirmSms () {
      this.loading = true
      await this.confirmationResult.confirm(this.smsCode)
        .then(() => {
          // not sure why, but captcha logo otherwise stays visible close to the bottom right corner
          document.getElementById('recaptcha-container').remove()
        })
        .catch(err => {
          console.log('confirmSms error', err)
          this.showErr(err)
          this.loading = false
        })
    },
    showErr (err) {
      const label = 'err_' + err.code.replace(/\/|-/gi, '_')
      const msg = this.$te(label) ? this.$t(label) : err.message
      this.errMsg = msg
      this.showErrMsg = true
    }
  },
  watch: {
    showErrMsg (showErrMsg) {
      if (!showErrMsg) {
        this.errMsg = null
      }
    }
  },
  mounted () {
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new this.$fb.fb.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.reCaptchaReady = true
        }
      })
    }
  }
}
</script>
